
.ContainerWrapper {
    position: relative; /* Set container position to relative */
  }
  
  .SwiperWrapper {
    position: relative; /* Set Swiper wrapper position to relative */
  }
  .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
    position: absolute; /* Position the pagination bullets absolutely */
    bottom: 0; /* Adjust the desired distance from the bottom of the container */
    left: 50%; /* Position the bullets in the center horizontally */
    transform: translateX(-50%); /* Center the bullets horizontally */
  }
.swiper-pagination-bullet {
    background-color: white; /* White background */
    border: none; /* No border */
    outline: none; /* No outline */
    width: 10px; /* Bullet width */
    height: 10px; /* Bullet height */
    margin: 0 3px ; /* Margin between bullets */
    transition: transform 0.2s ease-in-out; /* Transition for scaling effect */
  }
  
  .swiper-pagination-bullet-active {
    transform: scale(1.2); /* Slightly scaled size for active bullet */
  }