
html {
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
  box-sizing: border-box;
}
figure {
  margin: 0;
}
h1,
h2,
h3 {
  pointer-events: none;
}
p {
  pointer-events: none;
  margin-top: 0;
}
a {
  text-decoration: none;
  display: inline-block;
  width: -webkit-max-content;
  width: max-content;
}
a,
a:visited,
a:hover,
a:active {
  color: inherit;
}
img {
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.active > p {
  top: 10px;
  width: 100%;
  left: 0;
  position: absolute;
  z-index: 0;
  height: 10px;
  margin: 0;
  border-radius: 10px;
}
@media (max-width: 980px) {
  .active > p {
    top: 20px;
  }
}
.empty {
  margin: 0;
}
.active2 > p {
  top: 10px;
  width: 100%;
  left: 0;
  position: absolute;
  z-index: 0;
  height: 10px;
  margin: 0;
  border-radius: 10px;
}
@media (max-width: 980px) {
  .active2 > p {
    top: 20px;
  }
}
.empty2 {
  margin: 0;
}


.ContainerWrapper {
    position: relative; /* Set container position to relative */
  }
  
  .SwiperWrapper {
    position: relative; /* Set Swiper wrapper position to relative */
  }
  .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
    position: absolute; /* Position the pagination bullets absolutely */
    bottom: 0; /* Adjust the desired distance from the bottom of the container */
    left: 50%; /* Position the bullets in the center horizontally */
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); /* Center the bullets horizontally */
  }
.swiper-pagination-bullet {
    background-color: #00A49A; /* background */
    border: none; /* No border */
    outline: none; /* No outline */
    width: 10px; /* Bullet width */
    height: 10px; /* Bullet height */
    margin: 0 3px ; /* Margin between bullets */
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; /* Transition for scaling effect */
  }
  
  .swiper-pagination-bullet-active {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); /* Slightly scaled size for active bullet */
  }

.ContainerWrapper {
    position: relative; /* Set container position to relative */
  }
  
  .SwiperWrapper {
    position: relative; /* Set Swiper wrapper position to relative */
  }
  .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
    position: absolute; /* Position the pagination bullets absolutely */
    bottom: 0; /* Adjust the desired distance from the bottom of the container */
    left: 50%; /* Position the bullets in the center horizontally */
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); /* Center the bullets horizontally */
  }
.swiper-pagination-bullet {
    background-color: white; /* White background */
    border: none; /* No border */
    outline: none; /* No outline */
    width: 10px; /* Bullet width */
    height: 10px; /* Bullet height */
    margin: 0 3px ; /* Margin between bullets */
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; /* Transition for scaling effect */
  }
  
  .swiper-pagination-bullet-active {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); /* Slightly scaled size for active bullet */
  }
