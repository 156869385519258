.active > p {
  top: 10px;
  width: 100%;
  left: 0;
  position: absolute;
  z-index: 0;
  height: 10px;
  margin: 0;
  border-radius: 10px;
}
@media (max-width: 980px) {
  .active > p {
    top: 20px;
  }
}
.empty {
  margin: 0;
}
.active2 > p {
  top: 10px;
  width: 100%;
  left: 0;
  position: absolute;
  z-index: 0;
  height: 10px;
  margin: 0;
  border-radius: 10px;
}
@media (max-width: 980px) {
  .active2 > p {
    top: 20px;
  }
}
.empty2 {
  margin: 0;
}
