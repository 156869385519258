
html {
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
  box-sizing: border-box;
}
figure {
  margin: 0;
}
h1,
h2,
h3 {
  pointer-events: none;
}
p {
  pointer-events: none;
  margin-top: 0;
}
a {
  text-decoration: none;
  display: inline-block;
  width: max-content;
}
a,
a:visited,
a:hover,
a:active {
  color: inherit;
}
img {
  width: 100%;
  object-fit: contain;
  object-position: center;
}
